import $ from "jquery";

$(document).ready(function () {
  $("#retry_no").click(function (event) {
    event.preventDefault();
  });

  $("#retry_yes").click(function (event) {
    window.location.reload(true);
  });
});
