import $ from "jquery";
import "bootstrap/dist/js/bootstrap";

function releaseReservation(shouldRedirect) {
  // If there is already an ongoing AJAX call, abort it
  if (window.jqXHR) {
    window.jqXHR.abort();
  }

  $("#alerts").html($("#reservation-loading").html());

  $.ajax({
    type: "DELETE",
    url: "/api/reserve/",
    success: function (response) {
      window.reservationEndTime = -9999;
      if (shouldRedirect) {
        window.location.href = response.redirect_url;
      }
      $("#reservation_timeout_loading").hide();
      $("#reservation_timeout_options").show();
      $("#reservation_timeout_modal").modal("hide");
      // Display info message
      $("#alerts").html($("#reservation-released-msg").html());
      // Hide timer
      $("#reservation-nav").remove();
    },
    error: function (response) {
      window.location.href = "/";
    },
  });
}

function checkReservationTimer() {
  // If we have a reservation set the timer
  if ("reservationEndTime" in window) {
    // Call setTimeLeft every second
    var timeLeftRepeat = setInterval(setTimeLeft, 1000);
    setTimeLeft(timeLeftRepeat);
  }
}

function setTimeLeft(timeLeftRepeat) {
  var currentTime = (Date.now() / 1000) | 0;
  var timeLeftSecs = window.reservationEndTime - currentTime;
  var minutes = parseInt(timeLeftSecs / 60);
  var seconds = parseInt(timeLeftSecs % 60);

  // anything less that -9999 can be considered to probably be released,
  // or indicating that we have specifically released and not remade the
  // reservation
  if (timeLeftSecs <= -9999) {
    return;
    // if timeLeftSecs <= 0, set minutes and seconds to 0
  } else if (timeLeftSecs <= 0) {
    $("#reservation_timeout_modal").modal({
      keyboard: false,
      backdrop: "static",
    });

    // analytics call for when the countdown has finished
    window.ga("send", "event", "checkout", "time elapsed");

    clearInterval(timeLeftRepeat);
  } else if (timeLeftSecs > 0) {
    var timeLeftString = minutes + ":" + secs2string(seconds);

    var ticketsHeldLabel = "";
    $("#time-left-msg").html(ticketsHeldLabel);
    $(".time-left").html(timeLeftString);
  }
}

function secs2string(secs) {
  var secondsString = secs.toString();
  var secondsNum = parseInt(secs);

  if (secondsNum < 10) {
    return "0" + secondsString;
  } else {
    return secondsString;
  }
}

$(document).ready(function () {
  /////////////////////////////////////////////
  // Event handlers

  // Remake the reservation if user selects 'yes'
  $("#reserve_timeout_yes").click(function (event) {
    $("#reservation_timeout_options").hide();
    $("#reservation_timeout_loading").show();
    window.ga(
      "send",
      "event",
      "checkout",
      "time elapsed",
      "remake reservation"
    );
    remakeReservation();
  });

  // Release the reservation if the user selects 'no'
  $("#reserve_timeout_no").click(function (event) {
    $("#reservation_timeout_options").hide();
    $("#reservation_timeout_loading").show();
    window.ga(
      "send",
      "event",
      "checkout",
      "time elapsed",
      "release reservation"
    );
    releaseReservation(true);
  });

  // If user clicks on 'Remove' button in cart dropdown
  $("#reservation-nav a.edit").click(function (e) {
    e.preventDefault();
    releaseReservation();
  });

  // End Event handlders
  /////////////////////////////////////////////

  function reservationTimeout(errorMsg) {
    if (errorMsg) {
      var msg = errorMsg + window.rereserveLabel;
      $("#reservation_timeout_msg").text(msg);
    }
  }

  function remakeReservation() {
    $.ajax({
      type: "POST",
      url: "/api/reserve/",
      success: function (data) {
        if (data.error) {
          reservationTimeout(data.error);
        } else {
          // Update the time left
          // If on checkout page, also update transaction ID & seat number(s)
          window.reservationEndTime =
            ((Date.now() / 1000) | 0) + data.minutes_left * 60;
          checkReservationTimer();

          $("#seats_p").html(data.seats);
          $("input#id_transaction_id").val(data.transaction_uuid);

          $("#reservation_timeout_loading").hide();
          $("#reservation_timeout_options").show();
          $("#reservation_timeout_modal").modal("hide");
        }
      },
      error: function (jqXHR) {
        reservationTimeout(window.rereserveErrorMsg);
      },
    });
  }

  checkReservationTimer();
});
