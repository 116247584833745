import "jquery.cookie/jquery.cookie";
import $ from "jquery";

(function ($) {
  $(document).ready(function () {
    cookieDisplay();
  });
})($);

function cookieDisplay() {
  var hasSeenCookie = $.cookie("seen_cookie_policy") || false;
  if (!hasSeenCookie) {
    $("#cookie-footer").show();
    $("#cookie-close-button").on("click", function () {
      $("#cookie-footer").hide();
    });
    $.cookie("seen_cookie_policy", true, { path: "/" });
  }
}
