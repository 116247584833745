import $ from "jquery";

(function ($) {
  // Validate search before submitting
  $(document).on("submit", "#search-form, form.navbar-search", function () {
    if (!searchValidation($(this))) {
      return false;
    }
  });
})($);

// Search form validation (make sure keyword is not empty)
function searchValidation(searchForm) {
  var keyword = searchForm.find('input[name="keyword"]');

  if (keyword.length && keyword.val().trim().length < 1) {
    keyword.closest(".searchbox").addClass("keyword-missing");
    return false;
  }
  return true;
}
