import $ from "jquery";
import { flushAutocomplete } from "./autocomplete";

$(document).ready(function () {
  //////////////////////////////////////////////////////////////
  // Language selection

  // Set language for site
  function setLanguage(langCode) {
    if (!langCode) {
      return;
    }

    // get form, update language value and submit
    var form = $("#set-lang-form");
    form.find('input[name="language"]').val(langCode);
    form.submit();
  }

  // When selecting a language
  $(document).on("click", "footer a.lang-choice", function (event) {
    event.preventDefault();
    if (!$(this).hasClass("selected")) {
      var langCode = $(this).data("lang-code");
      flushAutocomplete();
      setLanguage(langCode);
    }
  });

  // Display map & seating plan modals
  $(document).on("click", ".seatingmap", function (e) {
    e.preventDefault();
    var eventId = $(this).data("event-id");
    $("#planModal-" + eventId).modal("show");
  });

  $(document).on("click", ".viewmap", function (e) {
    e.preventDefault();

    // Display modal for correct event (could be more than 1)
    var eventId = $(this).data("event-id");
    var mapModal = $("#mapModal-" + eventId);

    // Need to refresh map (otherwise place is not centred)
    var iframe = mapModal.find("iframe");
    var iframeSrc = iframe.data("src");
    if (iframeSrc) {
      iframe.attr("src", iframeSrc);
      iframe.data("src", "");
    }

    mapModal.modal("show");
  });
});
