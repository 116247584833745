import $ from "jquery";
import "jquery-ui/ui/widgets/autocomplete";

// EVENT HANDLERS
export function initialiseAutocomplete() {
  $.widget("ui.autocomplete", $.ui.autocomplete, {
    _renderItem: function (ul, item) {
      var li = $("<li>").attr("data-value", item.value);

      if (item.sub) {
        li.append(
          $("<a>").html(item.label + ", <small>" + item.sub + "</small>")
        );
      } else {
        li.append($("<a>").html(item.label));
      }
      li.on("touchstart", function () {
        li.click();
      });
      li.appendTo(ul);

      return li;
    },

    _renderMenu: function (ul, items) {
      var that = this;
      $.each(items, function (_, item) {
        that._renderItemData(ul, item);
      });
      // $( ul ).attr( 'tabindex', -1 ).addClass( 'dropdown-menu' );

      $(ul).addClass("auto-list");
    },

    _resizeMenu: function () {
      // this.menu.element.outerWidth( 'auto' );
      var inputWidth = $(this.element[0]).outerWidth();
      // this.menu.element.outerWidth(inputWidth);

      $(this.menu.element).css({
        "min-width": inputWidth,
      });
    },
  });

  $("input.auto-search").autocomplete({
    source: function (request, response) {
      var inputElem = $($(this)[0].element[0]);

      var cityKey = getSearchAttribute(inputElem, "city");
      var countryKey = false;

      if (!cityKey) {
        countryKey = getSearchAttribute(inputElem, "country");
      }

      getAutoCompleteResults(response, request.term, cityKey, countryKey);
    },
    select: function (event, ui) {
      if ($(event.target).data("auto-follow") === false) {
        $(event.target).data("auto-link", ui.item.data);
        $(event.target).data("auto-value", ui.item.value);
      } else {
        $(event.target).blur();
        if (isHomePage()) {
          // Send GA event
          window.ga("send", "event", "home page", "autocomplete", ui.item.data);
          window.dataLayer.push({
            gaEvent: [
              {
                eventCategory: "home page",
                eventAction: "autocomplete",
                eventLabel: ui.item.data,
              },
            ],
          });
        }
        document.location.href = ui.item.data;
      }
    },
    minLength: 3,
    delay: 300,
  });

  $("input.auto-search")
    .closest("form")
    .submit(function (event) {
      var inputElem = $(this).find("input.auto-search");
      var link = inputElem.data("auto-link");
      var value = inputElem.data("auto-value");
      var curVal = inputElem.val();

      if (link && value && value === curVal) {
        event.preventDefault();
        if (isHomePage()) {
          // Send GA event
          window.ga("send", "event", "home page", "autocomplete", link);
          window.dataLayer.push({
            gaEvent: [
              {
                eventCategory: "home page",
                eventAction: "autocomplete",
                eventLabel: link,
              },
            ],
          });
        }
        document.location.href = link;
      } else {
        var resultsMenu = inputElem.autocomplete("widget");

        if (resultsMenu) {
          var matchedLink = false;

          resultsMenu.find("li").each(function () {
            if (
              $(this).data("value").toLocaleLowerCase() ===
              curVal.toLocaleLowerCase()
            ) {
              if (matchedLink) {
                matchedLink = false;
                return false;
              } else {
                matchedLink = $(this).data("uiAutocompleteItem").data;
              }
            }
          });

          if (matchedLink) {
            event.preventDefault();
            if (isHomePage()) {
              // Send GA event
              window.ga("send", "event", "home page", "autocomplete", matchedLink);
              window.dataLayer.push({
                gaEvent: [
                  {
                    eventCategory: "home page",
                    eventAction: "autocomplete",
                    eventLabel: matchedLink,
                  },
                ],
              });
            }
            document.location.href = matchedLink;
          }
        }
      }
    });
}

function isHomePage() {
  if (document.location.pathname === "/") {
    return true;
  }
  return false;
}

function getAutoCompleteResults(callback, searchKey, cityKey, countryKey) {
  cityKey = cityKey || false;
  countryKey = countryKey || false;

  var searchData = { key: searchKey };
  var cacheKey = searchKey;

  if (cityKey) {
    searchData["city"] = cityKey;
    cacheKey = cacheKey.concat(cityKey);
  } else if (countryKey) {
    searchData["country"] = countryKey;
    cacheKey = cacheKey.concat(countryKey);
  }

  var results = [];

  window.jqXHR = $.ajax({
    url: "/autocomplete/",
    data: searchData,
    success: function (data) {
      if (data.cities && data.cities.length > 0) {
        for (var i = 0; i < data.cities.length; i++) {
          results.push({
            label: data.cities[i].description,
            data: data.cities[i].url,
            sub: data.cities[i].country_desc,
          });
        }
      }

      if (data.countries && data.countries.length > 0) {
        for (var j = 0; j < data.countries.length; j++) {
          results.push({
            label: data.countries[j].description,
            data: data.countries[j].url,
          });
        }
      }

      if (data.events && data.events.length > 0) {
        for (var k = 0; k < data.events.length; k++) {
          var event_data = {
            label: data.events[k].description,
            data: data.events[k].url,
          };

          if (data.events[k].city_desc) {
            event_data["sub"] = data.events[k].city_desc;
          } else if (data.events[k].country_desc) {
            event_data["sub"] = data.events[k].country_desc;
          }

          results.push(event_data);
        }
      }

      callback(results);

      $.jStorage.set(cacheKey, results, { TTL: 1800000 });
    },
    error: function () {
      callback(results);
    },
  });
}
function getSearchAttribute(autoSearchElem, type) {
  var searchKey = false;

  if (
    $("#text-filter-form select").val() === "filter" ||
    autoSearchElem.hasClass("mobile-city")
  ) {
    var searchType = autoSearchElem.data("search-type");

    if (searchType === type) {
      searchKey = autoSearchElem.data("search-key");
    }
  }

  return searchKey;
}
