import $ from "jquery";
import "jstorage";
import { initialiseAutocomplete } from "@lib/common-utils";

export function flushAutocomplete() {
  $.jStorage.flush();
}

$(document).ready(function () {
  initialiseAutocomplete();
});
